<template>
    <main class="main-page"  id="">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between align-items-center">
                        <div  class="col " >
                            <div class=" text-2xl text-primary font-bold" >
                                Daftar Pembayaran
                            </div>
                        </div>
                        <div  class="col-12 md:col-5 lg:col-4 " >
                            <span class="p-input-icon-left w-full">
                            <i class="pi pi-search" />
                            <InputText  placeholder="Search" class="w-full" :value="searchText" @input="debounce(() => { searchText = $event.target.value })"  />
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="flex align-items-center justify-content-around">
                            <div v-if="searchText" :class="filterTagClass">
                                Search
                                <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}</Chip>
                            </div>
                        </div>
                        <div >
                            <template v-if="showBreadcrumbs && $route.query.tag && !isSubPage">
                                <Breadcrumb :home="{icon: 'pi pi-home', to: '/pembayaran'}" :model="pageBreadCrumb" />
                            </template>
                            <!-- page records template -->
                            <div class="page-records"  >
                                <DataTable :lazy="true"   :loading="loading"     :value="records" dataKey="id" @sort="onSort($event)" class="" :showGridlines="false" :rowHover="true" responsiveLayout="stack">
                                    <Column  field="no_invoice" header="No Invoice" >
                                        <template #body="{data}">
                                            {{ data.no_invoice }}
                                        </template>
                                    </Column>
                                    <Column  field="tanggal_order" header="Tanggal Order" >
                                        <template #body="{data}">
                                            {{ data.tanggal_order }}
                                        </template>
                                    </Column>
                                    <Column  field="username_id" header="UID" >
                                        <template #body="{data}">
                                            {{ data.username_id }}
                                        </template>
                                    </Column>
                                    <Column  field="jenis_pembayaran" header="Jenis Pembayaran" >
                                        <template #body="{data}">
                                            {{ data.jenis_pembayaran }}
                                        </template>
                                    </Column>
                                    <Column  field="validasi" header="Validasi" >
                                        <template #body="{data}">
                                            {{ data.validasi }}
                                        </template>
                                    </Column>
                                    <Column  field="kode_transaksi" header="Total Transfer & Order" >
                                        <template #body="{data}">
                                            <Button class="p-button-text" icon="pi pi-eye" label="Lihat" v-if="data.kode_transaksi" @click="app.openPageDialog({ page: 'keranjang/list', url: `/keranjang/index/keranjang.kode_transaksi/${data.kode_transaksi}` , closeBtn: true })" />
                                        </template>
                                    </Column>
                                    <Column  field="file_bukti_tf" header="Bukti Transfer" >
                                        <template #body="{data}">
                                            <image-viewer image-size="small" image-preview-size="" :src="data.file_bukti_tf" width="50px" height="50px" class="img-fluid" :num-display="1">
                                            </image-viewer>
                                        </template>
                                    </Column>
                                    <Column  headerStyle="width: 2rem" headerClass="text-center">
                                        <template #body="{data}">
                                            <div class="flex justify-content-end">
                                                <SplitButton dropdownIcon="pi pi-bars" class="p-button dropdown-only p-button-text p-button-plain" :model="getActionMenuModel(data)">
                                                    <i></i>
                                                </SplitButton>
                                            </div>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            <!-- page loading indicator -->
                            <template v-if="loading">
                            </template>
                            <!-- end of page loading indicator-->
                            <!-- Empty record -->
                            <template v-if="pageReady && !records.length">
                                <div class="p-3 my-3 text-500 text-lg font-medium text-center">
                                    Data Tidak Ditemukan
                                </div>
                            </template>
                            <!-- end of empty record-->
                            <!-- pagination component-->
                            <template v-if="showFooter && pageReady">
                                <div class="flex justify-content-between align-items-center">
                                    <div class="flex justify-content-center flex-grow-0">
                                        <template v-if="auth.canView('pembayaran/delete')">
                                            <div v-if="selectedItems.length" class="m-2">
                                                <Button @click="deleteItem(selectedItems)" icon="pi pi-trash" class="p-button-danger" title="Delete Selected" />
                                            </div>
                                        </template>
                                    </div>
                                    <div v-if="paginate && totalPages > 1" class="flex-grow-1">
                                        <Paginator class="border-none bg-transparent py-3" :first="recordsPosition - 1" @page="(event)=>{pagination.page = event.page + 1}" :rows="pagination.limit" :totalRecords="totalRecords">
                                            <template #start>
                                                <span class="text-sm text-gray-500 px-2">
                                                Records <b>{{ recordsPosition }} of {{ totalRecords }}</b>
                                                </span>
                                            </template>
                                            <template #end>
                                            </template>
                                        </Paginator>
                                    </div>
                                </div>
                            </template>
                            <!-- end of pagination component-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { usePageStore } from 'src/store/page';
	import { useApp } from 'src/composables/app.js';
	import { useAuth } from 'src/composables/auth';
	import { useListPage } from 'src/composables/listpage.js';
	
	const props = defineProps({
		primaryKey : {
			type : String,
			default : 'id',
		},
		pageStoreKey: {
			type: String,
			default: 'PEMBAYARAN',
		},
		pageName: {
			type: String,
			default : 'pembayaran',
		},
		routeName: {
			type: String,
			default: 'pembayaranlist',
		},
		apiPath: {
			type: String,
			default: 'pembayaran/index',
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: false,
		},
		emptyRecordMsg: {
			type: String,
			default: "Data Tidak Ditemukan",
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Yakin ingin menghapus data ini?",
		},
		msgAfterDelete: {
			type: String,
			default: "Data berhasil dihapus",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 10,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: 'desc', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	
	const app = useApp();
	const auth = useAuth();
	
	const defaultStoreState = {
		filters: {
		},
		pagination: {
			page: props.page,
			limit: props.limit,
			sortBy: props.sortBy,
			sortType: props.sortType
		},
		searchText: props.search,
		primaryKey: props.primaryKey
	}
	const store = usePageStore(props.pageStoreKey,  defaultStoreState);
	
	// page hooks where logics resides
	const page = useListPage({ store, props });
	
	const {records, filters,  totalRecords,  selectedItems,  pagination,} = toRefs(store.state);
	const { pageReady, loading, searchText, } = toRefs(page.state);
	
	const {  pageBreadCrumb,   totalPages, recordsPosition, } = page.computedProps;
	
	const { load,    exportPage, debounce, clearSearch, onSort,  deleteItem,       } = page.methods;
	
	function getActionMenuModel(data){
		return [
		{
			label: "Upload Bukti",
			to: `/pembayaran/upload_bukti/${data.id}`,
			icon: "pi pi-upload",
			visible: auth.canView('pembayaran/upload_bukti')
		},
		{
			label: "Cancel",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-ban",
			visible: auth.canView('pembayaran/delete')
		}
	]
	}
	
	onMounted(()=>{ 
		const pageTitle = "Pembayaran";
		app.setPageTitle(props.routeName, pageTitle);
	});
</script>
<style scoped>
</style>
